<template>
  <div
    class="d-inline-flex"
    @click.stop.prevent="onGlobalClick"
  >
    <div
      v-if="isUserExists && isProfileExists"
      class="d-flex flex-column align-items-center"
    >
      <b-link
        v-if="!noLinks"
        :style="isLocked('customers', user && user.user_id) ? 'opacity: 0.5' : ''"
        class="position-relative"
        @click.prevent.stop="onClick"
      >
        <b-avatar
          :id="`profile-item-${uniqKey}`"
          :key="`${ukey}`"
          :variant="avatarVariant"
          :class="`border-${avatarVariant}`"
          badge
          :badge-variant="isOnline ? 'success' : 'secondary'"
          badge-offset="-2px"
          size="4rem"
          style="border: 3px solid;"
          :src="user.avatar"
        />
        <div
          v-if="user"
          class="b-flag"
        >
          <market-flag
            :market="user.country || user.market"
          />
        </div>
      </b-link>
      <span v-else>
        <b-avatar
          :id="`profile-item-${uniqKey}`"
          :key="`${ukey}`"
          :variant="avatarVariant"
          :class="`border-${avatarVariant}`"
          badge
          :badge-variant="isOnline ? 'success' : 'secondary'"
          badge-offset="-2px"
          size="4rem"
          style="border: 3px solid;"
          :src="user.avatar"
        />
      </span>
      <b-tooltip
        v-if="isLocked('customers', user && user.user_id)"
        :target="`profile-item-${uniqKey}`"
        placement="bottom"
        variant="warning"
        class="text-left"
      >
        {{ $t('eLt_locked', { email: isLockedByUser.email, id: isLockedByUser.by }) }}
      </b-tooltip>
    </div>
    <div
      class="d-flex flex-column align-items-start px-2"
    >
      <b v-if="isUserExists && isProfileExists">
        <b-link
          v-if="!noLinks"
          :class="isLocked('customers', user && user.user_id) ? 'text-secondary' : `text-${nameVariant}`"
          @click.prevent.stop="onClick"
        >
          {{ user.display_name }}
        </b-link>
        <span
          v-else
          :class="isLocked('customers', user && user.user_id) ? 'text-secondary' : `text-${nameVariant}`"
        >
          {{ user.display_name }}
        </span>
        <span
          v-if="user.deleted"
          class="text-danger"
        >&minus; {{ $t('eProfile_deleted') }}</span>
        <span
          v-if="user.duplicates"
          class="text-danger"
        >&minus; {{ $t('eProfile_duplicated') }}</span>
        <span
          v-if="label"
          :class="`text-${labelVariant}`"
        >&minus; {{ label }}</span>
      </b>
      <span
        v-if="isUserExists && canDisplay('user_id')"
        class="d-inline-flex flex-wrap align-items-start justify-content-start small mb-1"
      >
        <span><strong>{{ $t('eProfile_userId') }}:</strong> {{ user.user_id }}</span>
        <span
          v-if="isProfileExists && canDisplay('user_profile_id')"
        >&nbsp;-&nbsp;<strong>{{ $t('eProfile_profileId') }}:</strong> {{ user.user_profile_id }}</span>
        <span v-if="user.club_member && user.type === 'hostess'">&nbsp;-&nbsp;
          <b-badge v-if="!!user.club_access" variant="warning">
            {{ $t('eProfile_club_limit_short', { days: user.club_access[0], of: user.club_access[1] }) }}
          </b-badge>
          <b-badge v-else variant="warning">
            {{ $t('eProfile_club') }}
          </b-badge>
        </span>
      </span>
      <span v-if="isUserExists && canDisplay('email')">
        <span class="pr-1" :class="user.email_verified ? 'text-success' : 'text-danger'">
          <font-awesome-icon v-if="user.email_verified" size="sm" :icon="['fas', 'check']" />
          <font-awesome-icon v-else size="sm" :icon="['fas', 'triangle-exclamation']" />
        </span>
        {{ user.email }}
      </span>
      <span v-if="isUserExists && canDisplay('mobile')">
        <span class="pr-1" :class="user.mobile_verified ? 'text-success' : 'text-danger'">
          <font-awesome-icon v-if="user.mobile_verified" size="sm" :icon="['fas', 'check']" />
          <font-awesome-icon v-else size="sm" :icon="['fas', 'triangle-exclamation']" />
        </span>
        {{ user.mobile }}
      </span>
      <span v-if="isUserExists && canDisplay('status')">
        <div
          class="d-block flex-column"
          :class="tableLayout === 'table' ? 'text-center' : 'text-left'"
        >
          <font-awesome-icon
            :icon="['fas', 'circle']"
            :class="{
              'text-success': status === 'active',
              'text-warning': status === 'inactive',
              'text-warning': status === 'suspended',
              'text-danger': status === 'deleted',
              'text-primary': status === 'register',
              'text-secondary': status === 'hidden',
            }"
          />&nbsp;{{ status }}
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'UserProfileItem',
  props: {
    user: {
      type: Object,
      default: () => ({}),
      required: false
    },
    /*
    * Awailable values:
    * 'user_id';
    * 'user_profile_id';
    * 'email';
    * 'mobile'.
    * */
    fields: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    labelVariant: {
      type: String,
      default: 'info'
    },
    nameVariant: {
      type: String,
      default: 'info'
    },
    noLinks: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ukey: 0
    }
  },
  computed: {
    ...mapGetters({
      isLocked: 'shared/isLocked',
      isLockedBy: 'shared/isLockedBy',
      customersOnline: 'staffUser/customersOnline',
      customersOnlineCount: 'staffUser/customersOnlineCount',
      tableLayout: 'tableLayout'
    }),
    isUserExists () {
      return this.user.user_id !== null
    },
    isProfileExists () {
      return this.user.user_profile_id !== null
    },
    displayMobile () {
      let mobile = ''
      if (this.user.mobile_prefix) {
        mobile += `(${this.user.mobile_prefix})`
      }
      if (this.user.mobile) {
        if (this.user.mobile_prefix) {
          mobile += ` ${this.user.mobile.replace(this.user.mobile_prefix, '')}`
        } else {
          mobile += this.user.mobile
        }
      }
      return mobile
    },
    uniqKey () {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8)
    },
    isLockedByUser () {
      const user = this.isLockedBy('customers', this.user.user_id)
      if (user) {
        return user
      }
      return false
    },
    displayFields () {
      const def = [
        'user_id',
        'user_profile_id',
        'email'
      ]
      return def.concat(this.fields)
    },
    status () {
      return this.user?.status || ''
    },
    avatarVariant () {
      let variant = 'info'
      if (this.isLocked('customers', this.user?.user_id)) {
        variant = 'secondary'
      } else {
        switch (this.status) {
          case 'active':
            variant = 'success'
            break
          case 'inactive':
          case 'suspended':
            variant = 'warning'
            break
          case 'deleted':
            variant = 'danger'
            break
          case 'register':
            variant = 'primary'
            break
          case 'hidden':
            variant = 'secondary'
            break
          default:
            variant = 'info'
            break
        }
      }
      return variant
    },
    isOnline () {
      return this.customersOnline?.includes(this.user?.user_id) || false
    }
  },
  methods: {
    onClick () {
      if (!isServer) {
        window.open(`/customers/${this.user.user_id}`, '_blank').focus()
      }
      // this.$openGlobal('CustomerEditForm', this.user.id, 'customers')
      this.$emit('click', this.user.user_id)
    },
    onGlobalClick () {
      this.$emit('click', this.user.user_id)
    },
    canDisplay (fieldName) {
      return !!this.displayFields?.includes(fieldName)
    }
  }
}
</script>

<style>
.b-avatar .b-avatar-badge {
  font-size: 0.75rem !important;
}

.b-flag {
  display: flex;
  position: absolute;
  top: 4px;
  right: -4px;
  border: 1px solid #6c757d;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0.85;
}
</style>
